import type { ReactNode } from 'react'
import { createContext, useState } from 'react'
import {
  useErrorHandlingMutation,
  useErrorHandlingSubscription,
} from '@backoffice-frontend/data-fetching'
import type { FccDoorActuationError } from '@backoffice-frontend/graphql'
import { FccDoorState } from '@backoffice-frontend/graphql'
import type { AdVehicleDoorStatusFragment } from './AdVehicleDoorStatus.hook'
import {
  useFccDoorActuationStatusSubscription,
  useRequestDoorActuationMutation,
} from './AdVehicleDoorStatus.hook'

type AdVehicleDoorStatusValue = {
  isDoorActuationPossible: boolean
  errors: FccDoorActuationError[]
  isDoorsOpen: boolean
  pendingDoorActuation: FccDoorState | undefined
  desiredDoorState: FccDoorState
  handleDoorActuation: () => void
  mutationLoading: boolean
}

export const AdVehicleDoorStatusContext = createContext<
  AdVehicleDoorStatusValue | undefined
>(undefined)

type AdVehicleDoorStatusProviderProps = {
  vehicle: AdVehicleDoorStatusFragment
  children: ReactNode
}

export const AdVehicleDoorStatusProvider = ({
  vehicle,
  children,
}: AdVehicleDoorStatusProviderProps) => {
  const [isDoorActuationPossible, setIsDoorActuationPossible] = useState(false)
  const [errors, setErrors] = useState<FccDoorActuationError[]>([])
  const [optimisticDoorState, setOptimisticDoorState] = useState<
    FccDoorState | undefined
  >(undefined)
  const [pendingDoorActuation, setPendingDoorActuation] = useState<
    FccDoorState | undefined
  >(undefined)
  const vehicleId = vehicle.id

  const isDoorsOpen =
    optimisticDoorState !== undefined
      ? optimisticDoorState === FccDoorState.Open
      : (vehicle.latestADTelemetry?.rightSlidingDoorOpen?.value ?? false)
  const desiredDoorState =
    pendingDoorActuation ?? getNextDesiredDoorState(isDoorsOpen)
  useErrorHandlingSubscription(useFccDoorActuationStatusSubscription, {
    variables: { vehicleId },
    fetchPolicy: 'no-cache',
    onError: (e: unknown) => {
      console.error('Error in door status subscription', e)
      setPendingDoorActuation(undefined)
    },
    onData: ({ data }) => {
      const fccDoorActuationStatus = data.data?.fccDoorActuationStatus
      if (!fccDoorActuationStatus) {
        return
      }
      switch (fccDoorActuationStatus.__typename) {
        case 'FccDoorActuationNotPossibleStatus': {
          setIsDoorActuationPossible(() => false)
          break
        }
        case 'FccDoorActuationPossibleStatus': {
          setIsDoorActuationPossible(() => true)
          break
        }
        case 'FccDoorActuationRequestSuccessStatus': {
          setOptimisticDoorState(pendingDoorActuation)
          setPendingDoorActuation(undefined)
          break
        }
        case 'FccDoorActuationRequestFailureStatus': {
          setOptimisticDoorState(undefined)
          setPendingDoorActuation(undefined)
          console.error(fccDoorActuationStatus.errors)
          setErrors(fccDoorActuationStatus.errors)
          break
        }
      }
    },
  })

  const [requestDoorActuation, { loading: mutationLoading }] =
    useErrorHandlingMutation(useRequestDoorActuationMutation, {
      variables: { vehicleId, desiredDoorState },
      onError: () => {
        setPendingDoorActuation(undefined)
      },
    })

  const handleDoorActuation = () => {
    requestDoorActuation()
    setErrors([])
    setOptimisticDoorState(undefined)
    setPendingDoorActuation(desiredDoorState)
  }

  return (
    <AdVehicleDoorStatusContext.Provider
      value={{
        isDoorActuationPossible,
        desiredDoorState,
        errors,
        isDoorsOpen,
        pendingDoorActuation,
        handleDoorActuation,
        mutationLoading,
      }}
    >
      {children}
    </AdVehicleDoorStatusContext.Provider>
  )
}

const getNextDesiredDoorState = (open: boolean) =>
  open ? FccDoorState.Closed : FccDoorState.Open
