import { ApolloProvider } from '@apollo/client'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { IncludeTheme } from '@moia-dev/pace-web'
import '@moia-dev/pace-web/style.css'
import { AuthenticatedAppView } from '@backoffice-frontend/authenticated-app-view'
import { configureAmplify } from '@backoffice-frontend/authentication'
import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  RefContextProvider,
  Routes,
  appConfig,
  configureI18n,
} from '@backoffice-frontend/common'
import { useAppearance } from '@backoffice-frontend/dark-mode'
import { ENV, Environment } from '@backoffice-frontend/environment'
import { FleetMaintenanceStore } from '@backoffice-frontend/fleet-maintenance'
import { PublicAppView } from '@backoffice-frontend/login-view'
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  SuspenseWrapper,
  ThemeProvider,
  createTheme,
  darkThemeOptions,
  moiaThemeOptions,
} from '@backoffice-frontend/patterns'
import { StopNetworkStore } from '@backoffice-frontend/stop-network'
import { setupGoogleAnalytics } from '@backoffice-frontend/tracking'
import { Layout } from './Layout'
import { allAreas } from './allAreas'
import { getApolloClient } from './apolloClient'
import { loadSharedLibTranslations } from './sharedLibTranslations'

// configure app
appConfig()
configureAmplify()

const languageDetectionOptions = [Environment.local, Environment.test].includes(
  ENV,
)
  ? { order: ['localStorage'] }
  : {}

const i18n = configureI18n({
  supportedLngs: LANGUAGES,
  fallbackLng: DEFAULT_LANGUAGE,
  detection: languageDetectionOptions,
})

loadSharedLibTranslations()

setupGoogleAnalytics()

// theme has to be defined in app root as long as we use makeStyles
//
const lightTheme = createTheme(moiaThemeOptions)
const darkTheme = createTheme(darkThemeOptions)
const apolloClient = getApolloClient([StopNetworkStore, FleetMaintenanceStore])

export const App = () => {
  const { darkMode } = useAppearance()

  return (
    <>
      <IncludeTheme theme={darkMode ? 'dark' : 'light'} />
      <I18nextProvider i18n={i18n}>
        <StyledEngineProvider>
          <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <CssBaseline />
            <GlobalStyles />
            <HelmetProvider>
              <RouterProvider
                router={createBrowserRouter(
                  createRoutesFromElements(
                    <Route element={<Layout />}>
                      <Route
                        path={Routes.Auth.Login.path}
                        element={<PublicAppView />}
                      />
                      <Route
                        path="/*"
                        element={
                          <ApolloProvider client={apolloClient}>
                            <SuspenseWrapper>
                              <RefContextProvider>
                                <AuthenticatedAppView areas={allAreas} />
                              </RefContextProvider>
                            </SuspenseWrapper>
                          </ApolloProvider>
                        }
                      />
                    </Route>,
                  ),
                )}
              />
            </HelmetProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </I18nextProvider>
    </>
  )
}
