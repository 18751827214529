/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'
import { AdVehicleSeatingVehicleFragmentDoc } from '../../utils/seating/seating.hook'

const defaultOptions = {} as const
export type CabinSafetyCheckVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
    }>
  } | null
  adSeatsLayout: {
    __typename?: 'FccSeatLayout'
    thirdRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
  } | null
}

export type ConfirmCabinReadyMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type ConfirmCabinReadyMutation = {
  __typename?: 'Mutation'
  fccConfirmCabinReady: any | null
}

export const CabinSafetyCheckVehicleFragmentDoc = gql`
  fragment CabinSafetyCheckVehicle on Vehicle {
    id
    fccCurrentAdAlerts {
      alerts {
        id
        type
      }
    }
    ...AdVehicleSeatingVehicle
  }
  ${AdVehicleSeatingVehicleFragmentDoc}
`
export const ConfirmCabinReadyDocument = gql`
  mutation confirmCabinReady($vehicleId: ID!) {
    fccConfirmCabinReady(vehicleId: $vehicleId)
  }
`
export type ConfirmCabinReadyMutationFn = Apollo.MutationFunction<
  ConfirmCabinReadyMutation,
  ConfirmCabinReadyMutationVariables
>

/**
 * __useConfirmCabinReadyMutation__
 *
 * To run a mutation, you first call `useConfirmCabinReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCabinReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCabinReadyMutation, { data, loading, error }] = useConfirmCabinReadyMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useConfirmCabinReadyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmCabinReadyMutation,
    ConfirmCabinReadyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmCabinReadyMutation,
    ConfirmCabinReadyMutationVariables
  >(ConfirmCabinReadyDocument, options)
}
export type ConfirmCabinReadyMutationHookResult = ReturnType<
  typeof useConfirmCabinReadyMutation
>
export type ConfirmCabinReadyMutationResult =
  Apollo.MutationResult<ConfirmCabinReadyMutation>
export type ConfirmCabinReadyMutationOptions = Apollo.BaseMutationOptions<
  ConfirmCabinReadyMutation,
  ConfirmCabinReadyMutationVariables
>
