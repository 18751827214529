/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import {
  AdWaypointPassengersVehicleFragmentDoc,
  AdWaypointPassengersWaypointFragmentDoc,
} from '../../../../../ad-passenger-list/src/lib/AdWaypointPassengers/AdWaypointPassengers.hook'
import { GetPassengersWaypointFragmentDoc } from '../../../../../ad-passenger-list/src/lib/utils/passenger-filters.hook'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import {
  AlertVehicleMapVehicleFragmentDoc,
  AlertVehicleMapWaypointFragmentDoc,
} from '../AlertVehicleMap/AlertVehicleMap.hook'
import { AlertsModalSkeletonVehicleFragmentDoc } from '../components/AlertsModalSkeleton/AlertsModalSkeleton.hook'
import { BoardingPassengersWaypointFragmentDoc } from '../components/BoardingPassengers/BoardingPassengers.hook'
import { CabinSafetyCheckVehicleFragmentDoc } from '../components/CabinSafetyCheck/CabinSafetyCheck.hook'
import { DepartureVehicleFragmentDoc } from '../components/Departure/Departure.hook'

const defaultOptions = {} as const
export type WaypointAttendanceModalQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  taskNotificationTypes:
    | Array<Types.TaskNotificationType>
    | Types.TaskNotificationType
  taskNotificationStatus:
    | Array<Types.TaskNotificationStatus>
    | Types.TaskNotificationStatus
}>

export type WaypointAttendanceModalQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    isWheelchairAccessible: boolean | null
    isSelfDriving: boolean
    label: number | null
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      id: string
      adState: Types.FccWaypointState | null
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
        | {
            __typename: 'FccWaypointPickupAction'
            estimatedAt: string
            id: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
      >
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    }> | null
    relatedAlerts: {
      __typename?: 'TaskNotificationList'
      alerts: Array<
        | { __typename?: 'HubServiceNotification'; id: string }
        | { __typename?: 'IvcTaskNotification'; id: string }
        | {
            __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
          }
        | { __typename?: 'PassengerUnbuckled'; id: string }
        | { __typename?: 'RoadBlockageTaskNotification'; id: string }
        | { __typename?: 'ShiftDeviationNotification'; id: string }
        | { __typename?: 'StopAccessibilityDownvotedByDrivers'; id: string }
        | { __typename?: 'StopActivationFailedNotification'; id: string }
        | { __typename?: 'StopAreaMissingActiveStopsNotification'; id: string }
        | { __typename?: 'StopDownVotedByDrivers'; id: string }
        | { __typename?: 'StopPotentiallyUnreachable'; id: string }
        | { __typename?: 'StopValidationFailed'; id: string }
        | { __typename?: 'TimeManagementNotification'; id: string }
        | { __typename?: 'TripPrebookingNoAssignment'; id: string }
        | { __typename?: 'VehicleAvailabilityNotification'; id: string }
        | { __typename?: 'VehicleIncidentNotification'; id: string }
        | { __typename?: 'VehicleSurveillanceNotification'; id: string }
        | { __typename?: 'VehicleWaypointAttendance'; id: string }
      >
    } | null
    fccCurrentAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename: 'FccAdAlert'
        id: string
        type: Types.FccAdAlertType
        createdAt: string
      }>
    } | null
    latestADTelemetry: {
      __typename?: 'ADTelemetry'
      centralLockLocked: {
        __typename?: 'ADTelemetryBooleanWithTimestamp'
        value: boolean
      } | null
      rightSlidingDoorOpen: {
        __typename?: 'ADTelemetryBooleanWithTimestamp'
        value: boolean
        timestamp: string
      } | null
      speed: {
        __typename?: 'ADTelemetryFloatWithTimestamp'
        value: number
        timestamp: string
      } | null
    } | null
    alerts: {
      __typename?: 'TaskNotificationList'
      alerts: Array<
        | { __typename?: 'HubServiceNotification' }
        | { __typename?: 'IvcTaskNotification' }
        | {
            __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            trip: {
              __typename?: 'Trip'
              id: string
              customer: { __typename?: 'Customer'; id: string } | null
            } | null
          }
        | { __typename?: 'PassengerUnbuckled' }
        | { __typename?: 'RoadBlockageTaskNotification' }
        | { __typename?: 'ShiftDeviationNotification' }
        | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
        | { __typename?: 'StopActivationFailedNotification' }
        | { __typename?: 'StopAreaMissingActiveStopsNotification' }
        | { __typename?: 'StopDownVotedByDrivers' }
        | { __typename?: 'StopPotentiallyUnreachable' }
        | { __typename?: 'StopValidationFailed' }
        | { __typename?: 'TimeManagementNotification' }
        | { __typename?: 'TripPrebookingNoAssignment' }
        | { __typename?: 'VehicleAvailabilityNotification' }
        | { __typename?: 'VehicleIncidentNotification' }
        | { __typename?: 'VehicleSurveillanceNotification' }
        | { __typename?: 'VehicleWaypointAttendance' }
      >
    } | null
    latestLocation: {
      __typename?: 'VehicleLocation'
      heading: number | null
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
    taskNotifications: {
      __typename?: 'TaskNotificationList'
      tasks: Array<
        | {
            __typename: 'HubServiceNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'IvcTaskNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'PassengerUnbuckled'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'RoadBlockageTaskNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'ShiftDeviationNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopAccessibilityDownvotedByDrivers'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopActivationFailedNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopAreaMissingActiveStopsNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopDownVotedByDrivers'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopPotentiallyUnreachable'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopValidationFailed'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'TimeManagementNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'TripPrebookingNoAssignment'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleAvailabilityNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleIncidentNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleSurveillanceNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleWaypointAttendance'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
      >
    } | null
    adSeatsLayout: {
      __typename?: 'FccSeatLayout'
      thirdRowRight: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      thirdRowMiddle: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      thirdRowLeft: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowRight: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowMiddle: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowLeft: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
    } | null
    pooling: { __typename?: 'Pooling'; enabled: boolean } | null
  }
}

export type WaypointAttendanceModalWaypointFragment = {
  __typename?: 'FccWaypoint'
  id: string
  adState: Types.FccWaypointState | null
  actions: Array<
    | {
        __typename: 'FccWaypointDeliveryAction'
        estimatedAt: string
        id: string
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
    | {
        __typename: 'FccWaypointPickupAction'
        estimatedAt: string
        id: string
        adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
  >
  stop: {
    __typename?: 'Stop'
    routingLocation: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    }
  } | null
}

export type ReopenAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
}>

export type ReopenAlertMutation = {
  __typename?: 'Mutation'
  updateTaskNotificationStatus:
    | { __typename?: 'HubServiceNotification'; id: string }
    | { __typename?: 'IvcTaskNotification'; id: string }
    | {
        __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
      }
    | { __typename?: 'PassengerUnbuckled'; id: string }
    | { __typename?: 'RoadBlockageTaskNotification'; id: string }
    | { __typename?: 'ShiftDeviationNotification'; id: string }
    | { __typename?: 'StopAccessibilityDownvotedByDrivers'; id: string }
    | { __typename?: 'StopActivationFailedNotification'; id: string }
    | { __typename?: 'StopAreaMissingActiveStopsNotification'; id: string }
    | { __typename?: 'StopDownVotedByDrivers'; id: string }
    | { __typename?: 'StopPotentiallyUnreachable'; id: string }
    | { __typename?: 'StopValidationFailed'; id: string }
    | { __typename?: 'TimeManagementNotification'; id: string }
    | { __typename?: 'TripPrebookingNoAssignment'; id: string }
    | { __typename?: 'VehicleAvailabilityNotification'; id: string }
    | { __typename?: 'VehicleIncidentNotification'; id: string }
    | { __typename?: 'VehicleSurveillanceNotification'; id: string }
    | { __typename?: 'VehicleWaypointAttendance'; id: string }
}

export const WaypointAttendanceModalWaypointFragmentDoc = gql`
  fragment WaypointAttendanceModalWaypoint on FccWaypoint {
    id
    adState
    ...GetPassengersWaypoint
    ...BoardingPassengersWaypoint
    ...AdWaypointPassengersWaypoint
    ...AlertVehicleMapWaypoint
  }
  ${GetPassengersWaypointFragmentDoc}
  ${BoardingPassengersWaypointFragmentDoc}
  ${AdWaypointPassengersWaypointFragmentDoc}
  ${AlertVehicleMapWaypointFragmentDoc}
`
export const WaypointAttendanceModalDocument = gql`
  query WaypointAttendanceModal(
    $vehicleId: ID!
    $taskNotificationTypes: [TaskNotificationType!]!
    $taskNotificationStatus: [TaskNotificationStatus!]!
  ) {
    vehicle: vehicleByID(id: $vehicleId) {
      id
      ...DepartureVehicle
      ...AlertsModalSkeletonVehicle
      ...CabinSafetyCheckVehicle
      ...AdWaypointPassengersVehicle
      ...AdOnBoardPassengersVehicle
      ...AlertVehicleMapVehicle
      upcomingWaypoints: fccUpcomingWaypoints {
        ...WaypointAttendanceModalWaypoint
      }
      relatedAlerts: taskNotifications(
        types: [
          FC_PASSENGER_AUTHENTICATION_FALLBACK_REQUEST_NOTIFICATION
          FC_VEHICLE_CABIN_SAFETY_CHECK_REQUIRED_NOTIFICATION
        ]
        status: [IN_PROGRESS]
      ) {
        alerts: tasks {
          id
        }
      }
      fccCurrentAdAlerts {
        alerts {
          id
        }
      }
    }
  }
  ${DepartureVehicleFragmentDoc}
  ${AlertsModalSkeletonVehicleFragmentDoc}
  ${CabinSafetyCheckVehicleFragmentDoc}
  ${AdWaypointPassengersVehicleFragmentDoc}
  ${AdOnBoardPassengersVehicleFragmentDoc}
  ${AlertVehicleMapVehicleFragmentDoc}
  ${WaypointAttendanceModalWaypointFragmentDoc}
`

/**
 * __useWaypointAttendanceModalQuery__
 *
 * To run a query within a React component, call `useWaypointAttendanceModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaypointAttendanceModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaypointAttendanceModalQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      taskNotificationTypes: // value for 'taskNotificationTypes'
 *      taskNotificationStatus: // value for 'taskNotificationStatus'
 *   },
 * });
 */
export function useWaypointAttendanceModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    WaypointAttendanceModalQuery,
    WaypointAttendanceModalQueryVariables
  > &
    (
      | { variables: WaypointAttendanceModalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    WaypointAttendanceModalQuery,
    WaypointAttendanceModalQueryVariables
  >(WaypointAttendanceModalDocument, options)
}
export function useWaypointAttendanceModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WaypointAttendanceModalQuery,
    WaypointAttendanceModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    WaypointAttendanceModalQuery,
    WaypointAttendanceModalQueryVariables
  >(WaypointAttendanceModalDocument, options)
}
export function useWaypointAttendanceModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WaypointAttendanceModalQuery,
        WaypointAttendanceModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    WaypointAttendanceModalQuery,
    WaypointAttendanceModalQueryVariables
  >(WaypointAttendanceModalDocument, options)
}
export type WaypointAttendanceModalQueryHookResult = ReturnType<
  typeof useWaypointAttendanceModalQuery
>
export type WaypointAttendanceModalLazyQueryHookResult = ReturnType<
  typeof useWaypointAttendanceModalLazyQuery
>
export type WaypointAttendanceModalSuspenseQueryHookResult = ReturnType<
  typeof useWaypointAttendanceModalSuspenseQuery
>
export type WaypointAttendanceModalQueryResult = Apollo.QueryResult<
  WaypointAttendanceModalQuery,
  WaypointAttendanceModalQueryVariables
>
export const ReopenAlertDocument = gql`
  mutation reopenAlert($alertId: ID!) {
    updateTaskNotificationStatus(id: $alertId, status: NEW) {
      id
    }
  }
`
export type ReopenAlertMutationFn = Apollo.MutationFunction<
  ReopenAlertMutation,
  ReopenAlertMutationVariables
>

/**
 * __useReopenAlertMutation__
 *
 * To run a mutation, you first call `useReopenAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenAlertMutation, { data, loading, error }] = useReopenAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useReopenAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReopenAlertMutation,
    ReopenAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReopenAlertMutation, ReopenAlertMutationVariables>(
    ReopenAlertDocument,
    options,
  )
}
export type ReopenAlertMutationHookResult = ReturnType<
  typeof useReopenAlertMutation
>
export type ReopenAlertMutationResult =
  Apollo.MutationResult<ReopenAlertMutation>
export type ReopenAlertMutationOptions = Apollo.BaseMutationOptions<
  ReopenAlertMutation,
  ReopenAlertMutationVariables
>
