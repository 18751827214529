/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
/* eslint-disable */
export const namedOperations = {
  Query: {
    AdVehicleDrawerContent: 'AdVehicleDrawerContent',
    useServiceCancellationAlert: 'useServiceCancellationAlert',
    adFleetMapVehiclePreview: 'adFleetMapVehiclePreview',
    adFleetMapView: 'adFleetMapView',
    vehicleAuditLogDialogContent: 'vehicleAuditLogDialogContent',
    OperatorAvailability: 'OperatorAvailability',
    AdOperatorOverview: 'AdOperatorOverview',
    CallModal: 'CallModal',
    EmergencyStopModal: 'EmergencyStopModal',
    PassengerUnbuckledModal: 'PassengerUnbuckledModal',
    serviceCancellationModal: 'serviceCancellationModal',
    WaypointAttendanceModal: 'WaypointAttendanceModal',
    AlertModal: 'AlertModal',
    OperatorPromptMostUrgentAlert: 'OperatorPromptMostUrgentAlert',
    OperatorPromptAdAlertById: 'OperatorPromptAdAlertById',
    CustomerInfo: 'CustomerInfo',
    findVehicleByLabel: 'findVehicleByLabel',
    prebookingVehiclesByLabel: 'prebookingVehiclesByLabel',
    taskNotifications: 'taskNotifications',
    alertsTestingAlerts: 'alertsTestingAlerts',
    incidentSimulator: 'incidentSimulator',
    TestingOperatorConnect: 'TestingOperatorConnect',
    shiftIdByEmployeeLookup: 'shiftIdByEmployeeLookup',
    stopIdsByTripIdLookup: 'stopIdsByTripIdLookup',
    vehicleSearch: 'vehicleSearch',
    fleetControlAlertDrawer: 'fleetControlAlertDrawer',
    HscAlertsTable: 'HscAlertsTable',
    PrebookingAlertsTable: 'PrebookingAlertsTable',
    alertsBadgeCount: 'alertsBadgeCount',
    backofficeUserFleetsByEmail: 'backofficeUserFleetsByEmail',
    backofficeStatusById: 'backofficeStatusById',
    backofficeStatusGetLastVisit: 'backofficeStatusGetLastVisit',
    backofficeStatuses: 'backofficeStatuses',
    backofficeUpdateById: 'backofficeUpdateById',
    backofficeUpdateGetLastVisit: 'backofficeUpdateGetLastVisit',
    backofficeUpdatesByRights: 'backofficeUpdatesByRights',
    CapacityPlanningRmpFilename: 'CapacityPlanningRmpFilename',
    serviceAreaDropdown: 'serviceAreaDropdown',
    CapacityPlanningFiles: 'CapacityPlanningFiles',
    CapacityPlanningRmpFiles: 'CapacityPlanningRmpFiles',
    CapacityPlanningTable: 'CapacityPlanningTable',
    serviceAreaByIdWithoutLocationAttributes:
      'serviceAreaByIdWithoutLocationAttributes',
    serviceAreaByIdWithLocationAttributes:
      'serviceAreaByIdWithLocationAttributes',
    globalServiceAreaUuid: 'globalServiceAreaUuid',
    getServiceAreas: 'getServiceAreas',
    accountViewCanary: 'accountViewCanary',
    hubs: 'hubs',
    queryEmployeeAutocomplete: 'queryEmployeeAutocomplete',
    serviceAreas: 'serviceAreas',
    queryCustomers: 'queryCustomers',
    queryCustomer: 'queryCustomer',
    queryCustomerHeader: 'queryCustomerHeader',
    queryCustomerGDPRSelfAssessment: 'queryCustomerGDPRSelfAssessment',
    queryTripGDPRSelfAssessment: 'queryTripGDPRSelfAssessment',
    customerSearch: 'customerSearch',
    queryReferees: 'queryReferees',
    ReferrerInfo: 'ReferrerInfo',
    getTripCustomerByTripId: 'getTripCustomerByTripId',
    getTripCustomerFeedbackByTripId: 'getTripCustomerFeedbackByTripId',
    employeesByID: 'employeesByID',
    vehiclesByIDs: 'vehiclesByIDs',
    dsBossFileByScenarioId: 'dsBossFileByScenarioId',
    dsBossScenarioDetailsByScenarioId: 'dsBossScenarioDetailsByScenarioId',
    DsBossScenariosByServiceAreaId: 'DsBossScenariosByServiceAreaId',
    employeeGetScheduledBlocks: 'employeeGetScheduledBlocks',
    queryEmployeeList: 'queryEmployeeList',
    getShiftsForDriver: 'getShiftsForDriver',
    employeeCountQuery: 'employeeCountQuery',
    EmployeeQueryNotes: 'EmployeeQueryNotes',
    getEmployeeMessages: 'getEmployeeMessages',
    queryHub: 'queryHub',
    latestHubLogRecord: 'latestHubLogRecord',
    queryEmployeeFullProfile: 'queryEmployeeFullProfile',
    queryEmployeeProfilePicture: 'queryEmployeeProfilePicture',
    fleetMaintenanceAreaInactiveVehicles:
      'fleetMaintenanceAreaInactiveVehicles',
    VehicleIdMultiselect: 'VehicleIdMultiselect',
    WorkOrders: 'WorkOrders',
    MainInspectionProcesses: 'MainInspectionProcesses',
    mainInspectionTestFacilities: 'mainInspectionTestFacilities',
    mileageBasedInspectionProcesses: 'mileageBasedInspectionProcesses',
    mileageBasedInspectionTestFacilities:
      'mileageBasedInspectionTestFacilities',
    VicProcesses: 'VicProcesses',
    VehicleSimulatorMap: 'VehicleSimulatorMap',
    AvailableVehicles: 'AvailableVehicles',
    OptimizationVehicleDetails: 'OptimizationVehicleDetails',
    OptimizationVehicles: 'OptimizationVehicles',
    VehicleSearchResultList: 'VehicleSearchResultList',
    ADVehicleSimulatorPage: 'ADVehicleSimulatorPage',
    VehicleSimulatorPage: 'VehicleSimulatorPage',
    VehicleSimulatorDetails: 'VehicleSimulatorDetails',
    FleetMapVehicleQuickActions: 'FleetMapVehicleQuickActions',
    StopDeactivation: 'StopDeactivation',
    fleetMapVehiclePreview: 'fleetMapVehiclePreview',
    fleetMapView: 'fleetMapView',
    independentAlerts: 'independentAlerts',
    vehicleTripView: 'vehicleTripView',
    VehicleDrawerContent: 'VehicleDrawerContent',
    vehicleDrawerShiftCard: 'vehicleDrawerShiftCard',
    KeepOutZones: 'KeepOutZones',
    hereMapViewAdPolyData: 'hereMapViewAdPolyData',
    hereMapViewServiceAreaData: 'hereMapViewServiceAreaData',
    useRemainingRoute: 'useRemainingRoute',
    LatestVehicleAddress: 'LatestVehicleAddress',
    fleetMapAreas: 'fleetMapAreas',
    fleets: 'fleets',
    areaList: 'areaList',
    hubDetail: 'hubDetail',
    hubVehicleCount: 'hubVehicleCount',
    latestLocationChange: 'latestLocationChange',
    allChanges: 'allChanges',
    serviceAreaByIdWithAreaGeometry: 'serviceAreaByIdWithAreaGeometry',
    VehicleByPhoneNumber: 'VehicleByPhoneNumber',
    getTripPaymentByTripId: 'getTripPaymentByTripId',
    RioAddressAutocomplete: 'RioAddressAutocomplete',
    RioAddressFromLocation: 'RioAddressFromLocation',
    RioAttachmentDownloadUrl: 'RioAttachmentDownloadUrl',
    RioTempAttachmentDownloadUrl: 'RioTempAttachmentDownloadUrl',
    RioAttachmentUploadUrl: 'RioAttachmentUploadUrl',
    RioEmployee: 'RioEmployee',
    RioEmployeeList: 'RioEmployeeList',
    RioLatestTemplate: 'RioLatestTemplate',
    RioReport: 'RioReport',
    RioReports: 'RioReports',
    RioVehicle: 'RioVehicle',
    RioVehicleDetails: 'RioVehicleDetails',
    RioTrip: 'RioTrip',
    safetyDriverContentWrapper: 'safetyDriverContentWrapper',
    safetyDriverInterfaceAreaContentView:
      'safetyDriverInterfaceAreaContentView',
    serviceAreaByIdForForm: 'serviceAreaByIdForForm',
    ServiceCancellationDialog: 'ServiceCancellationDialog',
    underAndOverSupply: 'underAndOverSupply',
    upcomingHubActivity: 'upcomingHubActivity',
    avoidanceZonesByServiceArea: 'avoidanceZonesByServiceArea',
    serviceAreaGeoData: 'serviceAreaGeoData',
    employeeFieldShiftCreation: 'employeeFieldShiftCreation',
    backofficeUserByGroup: 'backofficeUserByGroup',
    overviewDashboardShifts: 'overviewDashboardShifts',
    shiftById: 'shiftById',
    shiftHubReturnUtilization: 'shiftHubReturnUtilization',
    shiftPlannedHubReturnSectionOptions: 'shiftPlannedHubReturnSectionOptions',
    employeeLogByShiftId: 'employeeLogByShiftId',
    employeeSearchQuery: 'employeeSearchQuery',
    shiftsActivities: 'shiftsActivities',
    demandAndSupplyData: 'demandAndSupplyData',
    shiftsSupplyData: 'shiftsSupplyData',
    shiftTypes: 'shiftTypes',
    stopInsightDataForTheLastDays: 'stopInsightDataForTheLastDays',
    stopNetworkAlertCounts: 'stopNetworkAlertCounts',
    roadSegmentSuggestionsInsidePolygon: 'roadSegmentSuggestionsInsidePolygon',
    queryAreaById: 'queryAreaById',
    queryAreaWithActions: 'queryAreaWithActions',
    searchPolygonContentV3: 'searchPolygonContentV3',
    entityChangelog: 'entityChangelog',
    entityLatestChange: 'entityLatestChange',
    activationsByRoadBlockage: 'activationsByRoadBlockage',
    roadBlockageById: 'roadBlockageById',
    roadBlockageChangelog: 'roadBlockageChangelog',
    roadBlockageLatestChange: 'roadBlockageLatestChange',
    roadBlockages: 'roadBlockages',
    roadSegmentSuggestion: 'roadSegmentSuggestion',
    downloadKML: 'downloadKML',
    stopNetworkSearchV3: 'stopNetworkSearchV3',
    queryStopByID: 'queryStopByID',
    stopInsights: 'stopInsights',
    stopSuggestion: 'stopSuggestion',
    stopValidation: 'stopValidation',
    queryStopWithActions: 'queryStopWithActions',
    activationsByTurnRestriction: 'activationsByTurnRestriction',
    turnRestrictionById: 'turnRestrictionById',
    turnRestrictionChangelog: 'turnRestrictionChangelog',
    turnRestrictionLatestChange: 'turnRestrictionLatestChange',
    turnRestrictions: 'turnRestrictions',
    queryHeatmapById: 'queryHeatmapById',
    paymentStatusByTripId: 'paymentStatusByTripId',
    getTripGeneralByTripId: 'getTripGeneralByTripId',
    gettripByInvoiceId: 'gettripByInvoiceId',
    gettripByPaymentId: 'gettripByPaymentId',
    getTripByPspTransactionId: 'getTripByPspTransactionId',
    getTripOrderInfoByTripId: 'getTripOrderInfoByTripId',
    getTripRequestAndExecutionByTripId: 'getTripRequestAndExecutionByTripId',
    getStatusByTripId: 'getStatusByTripId',
    paginatedTripsByCustomerIdSortedByPickup:
      'paginatedTripsByCustomerIdSortedByPickup',
    paginatedTripsByVehicleIdSortedByPickup:
      'paginatedTripsByVehicleIdSortedByPickup',
    backofficeUserManagementGroups: 'backofficeUserManagementGroups',
    backofficeUserByEmail: 'backofficeUserByEmail',
    BackofficeUsersToAddToGroup: 'BackofficeUsersToAddToGroup',
    backofficeUserManagementGroupByName: 'backofficeUserManagementGroupByName',
    BackofficeGroupHistory: 'BackofficeGroupHistory',
    BackofficeGroupUsers: 'BackofficeGroupUsers',
    backofficeUserManagementUsersHistory:
      'backofficeUserManagementUsersHistory',
    backofficeUserManagementUserTenantsDetails:
      'backofficeUserManagementUserTenantsDetails',
    BackofficeUserDetailView: 'BackofficeUserDetailView',
    BackofficeUserHistoryUserById: 'BackofficeUserHistoryUserById',
    BackofficeUserHistoryFleets: 'BackofficeUserHistoryFleets',
    BackofficeUserHistoryUserByEmail: 'BackofficeUserHistoryUserByEmail',
    BackofficeUserHistoryView: 'BackofficeUserHistoryView',
    backofficeUserManagementUserFormDetails:
      'backofficeUserManagementUserFormDetails',
    userTenants: 'userTenants',
    BackofficeUsers: 'BackofficeUsers',
    VehicleStateChangeDialog: 'VehicleStateChangeDialog',
    WorkOrdersTable: 'WorkOrdersTable',
    DownloadWorkOrdersAsCsv: 'DownloadWorkOrdersAsCsv',
    queryEmployees: 'queryEmployees',
    vehicleGroupByID: 'vehicleGroupByID',
    vehicleGroups: 'vehicleGroups',
    assignedCdisBox: 'assignedCdisBox',
    unassignedCdisBoxes: 'unassignedCdisBoxes',
    TabletSection: 'TabletSection',
    MapCardMapVehicle: 'MapCardMapVehicle',
    MapCardMapAreas: 'MapCardMapAreas',
    getTotalOpenOrdersForVehicle: 'getTotalOpenOrdersForVehicle',
    getVehicleLabel: 'getVehicleLabel',
    VehicleListsDropdown: 'VehicleListsDropdown',
    fleetMapMenuItem: 'fleetMapMenuItem',
    VehicleQuickActionsButton: 'VehicleQuickActionsButton',
    HubReturnsWidget: 'HubReturnsWidget',
    vehiclesForVehicleList: 'vehiclesForVehicleList',
    vehicleLists: 'vehicleLists',
    QuickActionsCell: 'QuickActionsCell',
    VehicleListsFilter: 'VehicleListsFilter',
    vehicleDetails: 'vehicleDetails',
    adTelematics: 'adTelematics',
    exportVehiclesToCsv: 'exportVehiclesToCsv',
    HubViewDashboardArrivals: 'HubViewDashboardArrivals',
    hubViewDashboardDepartures: 'hubViewDashboardDepartures',
    hubViewDashboardOverview: 'hubViewDashboardOverview',
    damageReports: 'damageReports',
    damageReportsByVehicle: 'damageReportsByVehicle',
    vehicleChecks: 'vehicleChecks',
    vehicleListByID: 'vehicleListByID',
    vehicleParts: 'vehicleParts',
    serviceAreaByUuid: 'serviceAreaByUuid',
    vehicleChanges: 'vehicleChanges',
    vehicleInteractions: 'vehicleInteractions',
  },
  Mutation: {
    initiateServiceCancellationWithoutIncident:
      'initiateServiceCancellationWithoutIncident',
    requestOperatorCall: 'requestOperatorCall',
    setOperatorAvailability: 'setOperatorAvailability',
    logoutOperator: 'logoutOperator',
    loginOperator: 'loginOperator',
    initiateServiceCancellationWithIncident:
      'initiateServiceCancellationWithIncident',
    resolveServiceCancellationToContinueTrips:
      'resolveServiceCancellationToContinueTrips',
    reopenAlert: 'reopenAlert',
    requestDoorActuation: 'requestDoorActuation',
    confirmManualNoShow: 'confirmManualNoShow',
    confirmManualCheckIn: 'confirmManualCheckIn',
    confirmCabinReady: 'confirmCabinReady',
    resolveAlert: 'resolveAlert',
    confirmCancelAllTrips: 'confirmCancelAllTrips',
    MarkPromptAsHandled: 'MarkPromptAsHandled',
    TakeOverAdAlert: 'TakeOverAdAlert',
    requestCabinVideoStreamCredentials: 'requestCabinVideoStreamCredentials',
    TogglePooling: 'TogglePooling',
    assignVehicleToAlert: 'assignVehicleToAlert',
    setTaskNotificationPending: 'setTaskNotificationPending',
    takeOverOpenAdAlert: 'takeOverOpenAdAlert',
    unassignAdAlert: 'unassignAdAlert',
    updateTaskNotificationNote: 'updateTaskNotificationNote',
    updateTaskNotificationStatus: 'updateTaskNotificationStatus',
    resolveTaskNotification: 'resolveTaskNotification',
    triggerTaskNotification: 'triggerTaskNotification',
    simulateCabinReadyCheckRequestedEvent:
      'simulateCabinReadyCheckRequestedEvent',
    simulateCloseDoorRequestedEvent: 'simulateCloseDoorRequestedEvent',
    simulateDoorActuationResponseEvent: 'simulateDoorActuationResponseEvent',
    activateSimulatedADVehicleIncident: 'activateSimulatedADVehicleIncident',
    deactivateSimulatedADVehicleIncident:
      'deactivateSimulatedADVehicleIncident',
    BackofficeUserManagementSwapGroups: 'BackofficeUserManagementSwapGroups',
    BackofficeLogoutUser: 'BackofficeLogoutUser',
    backofficeStatusCreate: 'backofficeStatusCreate',
    backofficeStatusDelete: 'backofficeStatusDelete',
    backofficeStatusSetLastVisit: 'backofficeStatusSetLastVisit',
    backofficeStatusUpdate: 'backofficeStatusUpdate',
    backofficeStatusDeleteMediaByFileName:
      'backofficeStatusDeleteMediaByFileName',
    backofficeUpdateCreate: 'backofficeUpdateCreate',
    backofficeUpdateDelete: 'backofficeUpdateDelete',
    backofficeUpdatesGetPresignedUrl: 'backofficeUpdatesGetPresignedUrl',
    backofficeUpdateSetLastVisit: 'backofficeUpdateSetLastVisit',
    backofficeUpdateUpdate: 'backofficeUpdateUpdate',
    backofficeUpdatesGetMediaPresignedUrlByFileName:
      'backofficeUpdatesGetMediaPresignedUrlByFileName',
    capacityPlanningDialog: 'capacityPlanningDialog',
    CapacityPlanningDelete: 'CapacityPlanningDelete',
    CapacityPlanningUpdateStatus: 'CapacityPlanningUpdateStatus',
    disableCustomer: 'disableCustomer',
    enableCustomer: 'enableCustomer',
    updateCustomer: 'updateCustomer',
    dsBossCreateOrUpdateScenario: 'dsBossCreateOrUpdateScenario',
    dsBossDeleteScenario: 'dsBossDeleteScenario',
    dsBossSaveFinalOutputToDataset: 'dsBossSaveFinalOutputToDataset',
    assignDeputySupervisor: 'assignDeputySupervisor',
    assignSupervisor: 'assignSupervisor',
    employeeResetPassword: 'employeeResetPassword',
    employeeBlock: 'employeeBlock',
    employeeCreateNote: 'employeeCreateNote',
    employeeCreateNotes: 'employeeCreateNotes',
    employeeCreateScheduledBlock: 'employeeCreateScheduledBlock',
    employeeDeleteNotes: 'employeeDeleteNotes',
    employeeDeleteScheduledBlock: 'employeeDeleteScheduledBlock',
    employeeNoteCreateFileGetUrl: 'employeeNoteCreateFileGetUrl',
    employeeNoteCreatePreSignedUrl: 'employeeNoteCreatePreSignedUrl',
    employeeResendEmailVerification: 'employeeResendEmailVerification',
    employeeUnblock: 'employeeUnblock',
    employeeUpdateNote: 'employeeUpdateNote',
    updateEmployeeProfileData: 'updateEmployeeProfileData',
    employeeUpdateScheduledBlock: 'employeeUpdateScheduledBlock',
    insertHubCheckedInLogRecord: 'insertHubCheckedInLogRecord',
    insertHubCheckedOutLogRecord: 'insertHubCheckedOutLogRecord',
    sendEmployeeMessage: 'sendEmployeeMessage',
    sendMassMessage: 'sendMassMessage',
    updateEmployeePicture: 'updateEmployeePicture',
    CreateMultipleWorkOrdersDialog: 'CreateMultipleWorkOrdersDialog',
    transitionMainInspectionProcessToNextState:
      'transitionMainInspectionProcessToNextState',
    transitionMileageBasedInspectionProcessToNextState:
      'transitionMileageBasedInspectionProcessToNextState',
    transitionVICProcessToNextState: 'transitionVICProcessToNextState',
    updateMainInspectionDocumentCosts: 'updateMainInspectionDocumentCosts',
    updateMainInspectionProcessData: 'updateMainInspectionProcessData',
    updateMileageBasedInspectionDocumentCosts:
      'updateMileageBasedInspectionDocumentCosts',
    updateMileageBasedInspectionProcessData:
      'updateMileageBasedInspectionProcessData',
    updateVICProcessData: 'updateVICProcessData',
    updateVehicleRebalancing: 'updateVehicleRebalancing',
    deleteServiceBreak: 'deleteServiceBreak',
    triggerArrivalEvent: 'triggerArrivalEvent',
    triggerDepartureEvent: 'triggerDepartureEvent',
    updateSimulatedADVehicle: 'updateSimulatedADVehicle',
    updateSimulatedVehicle: 'updateSimulatedVehicle',
    cancelTrip: 'cancelTrip',
    provideCancellationReason: 'provideCancellationReason',
    fccRequestFleetShutdown: 'fccRequestFleetShutdown',
    fccBroadcastFleetShutdownMessage: 'fccBroadcastFleetShutdownMessage',
    createHub: 'createHub',
    updateAreaCapacity: 'updateAreaCapacity',
    updateHub: 'updateHub',
    fccPrepareOperatorUser: 'fccPrepareOperatorUser',
    feeRefundByTripId: 'feeRefundByTripId',
    refundByTripId: 'refundByTripId',
    rioDeleteReport: 'rioDeleteReport',
    rioSetReportStatus: 'rioSetReportStatus',
    rioStoreReport: 'rioStoreReport',
    updateServiceArea: 'updateServiceArea',
    requestVehicleServiceCancellation: 'requestVehicleServiceCancellation',
    setShiftEmployeeStatus: 'setShiftEmployeeStatus',
    shiftConfirmTaskNotification: 'shiftConfirmTaskNotification',
    shiftDeletePlannedHubReturnSection: 'shiftDeletePlannedHubReturnSection',
    shiftOverrideScheduledHubReturn: 'shiftOverrideScheduledHubReturn',
    shiftSetPlannedHubReturnSection: 'shiftSetPlannedHubReturnSection',
    shiftDashboardSetShiftComment: 'shiftDashboardSetShiftComment',
    shiftOverrideType: 'shiftOverrideType',
    shiftSetAbsence: 'shiftSetAbsence',
    shiftsDeleteActivity: 'shiftsDeleteActivity',
    shiftsCreateActivity: 'shiftsCreateActivity',
    shiftsUpdateActivity: 'shiftsUpdateActivity',
    createAction: 'createAction',
    deleteAction: 'deleteAction',
    updateAction: 'updateAction',
    createArea: 'createArea',
    deleteArea: 'deleteArea',
    updateArea: 'updateArea',
    addRoadBlockageActivation: 'addRoadBlockageActivation',
    createRoadBlockage: 'createRoadBlockage',
    deleteRoadBlockage: 'deleteRoadBlockage',
    removeRoadBlockageActivation: 'removeRoadBlockageActivation',
    updateRoadBlockage: 'updateRoadBlockage',
    updateRoadBlockageActivation: 'updateRoadBlockageActivation',
    createStop: 'createStop',
    deleteStop: 'deleteStop',
    updateStop: 'updateStop',
    addTurnRestrictionActivation: 'addTurnRestrictionActivation',
    createTurnRestriction: 'createTurnRestriction',
    deleteTurnRestriction: 'deleteTurnRestriction',
    removeTurnRestrictionActivation: 'removeTurnRestrictionActivation',
    updateTurnRestriction: 'updateTurnRestriction',
    updateTurnRestrictionActivation: 'updateTurnRestrictionActivation',
    BackofficeUserManagementRemoveUserFromGroup:
      'BackofficeUserManagementRemoveUserFromGroup',
    BackofficeUserManagementUpdateBackofficeGroup:
      'BackofficeUserManagementUpdateBackofficeGroup',
    BackofficeUserManagementCreateBackofficeGroup:
      'BackofficeUserManagementCreateBackofficeGroup',
    BackofficeUserManagementDeleteBackofficeGroup:
      'BackofficeUserManagementDeleteBackofficeGroup',
    BackofficeUserManagementUpdateBackofficeUser:
      'BackofficeUserManagementUpdateBackofficeUser',
    BackofficeUserManagementEnableBackofficeUser:
      'BackofficeUserManagementEnableBackofficeUser',
    BackofficeUserManagementDeleteBackofficeUser:
      'BackofficeUserManagementDeleteBackofficeUser',
    BackofficeUserManagementDisableBackofficeUser:
      'BackofficeUserManagementDisableBackofficeUser',
    BackofficeUserManagementLogoutBackofficeUser:
      'BackofficeUserManagementLogoutBackofficeUser',
    BackofficeUserManagementResetMfaBackofficeUser:
      'BackofficeUserManagementResetMfaBackofficeUser',
    backofficeUserManagementCreateBackofficeUser:
      'backofficeUserManagementCreateBackofficeUser',
    backofficeUserManagementAddUserToGroup:
      'backofficeUserManagementAddUserToGroup',
    FlashVehicleMenuItemMutation: 'FlashVehicleMenuItemMutation',
    WorkOrderDeletion: 'WorkOrderDeletion',
    WorkOrderTableStatusCell: 'WorkOrderTableStatusCell',
    EditStateChangeInactiveDialog: 'EditStateChangeInactiveDialog',
    activateAC: 'activateAC',
    activateVehicle: 'activateVehicle',
    archiveVehicle: 'archiveVehicle',
    closeVehicleByBackofficeUser: 'closeVehicleByBackofficeUser',
    closeVehicleForEmployeeInEmergency: 'closeVehicleForEmployeeInEmergency',
    deactivateVehicle: 'deactivateVehicle',
    flashVehicle: 'flashVehicle',
    openVehicleForBackofficeUser: 'openVehicleForBackofficeUser',
    openVehicleForEmployee: 'openVehicleForEmployee',
    openVehicleForEmployeeInEmergency: 'openVehicleForEmployeeInEmergency',
    scheduleStateChange: 'scheduleStateChange',
    createVehicleGroup: 'createVehicleGroup',
    deleteVehicleGroup: 'deleteVehicleGroup',
    updateVehicleGroup: 'updateVehicleGroup',
    assignCdisBoxToVehicle: 'assignCdisBoxToVehicle',
    unassignCdisBoxFromVehicle: 'unassignCdisBoxFromVehicle',
    HardwareToggleButton: 'HardwareToggleButton',
    assignTabletToVehicle: 'assignTabletToVehicle',
    unassignTabletFromVehicle: 'unassignTabletFromVehicle',
    SeatConfigurationCard: 'SeatConfigurationCard',
    CreateSingleWorkOrderDialog: 'CreateSingleWorkOrderDialog',
    AddRemoveVehiclesDialog: 'AddRemoveVehiclesDialog',
    CreateVehicleListDialog: 'CreateVehicleListDialog',
    DeleteVehicleListDialog: 'DeleteVehicleListDialog',
    EditVehicleListDialog: 'EditVehicleListDialog',
    deprioritizeVehicle: 'deprioritizeVehicle',
    prioritizeVehicle: 'prioritizeVehicle',
    FlashVehicleMenuItem: 'FlashVehicleMenuItem',
    matchDriver: 'matchDriver',
    unmatchDriver: 'unmatchDriver',
    acknowledgeVehicleCheckAnswer: 'acknowledgeVehicleCheckAnswer',
    createDamageReport: 'createDamageReport',
    createVehicle: 'createVehicle',
    createVehicleCheck: 'createVehicleCheck',
    deleteScheduleVehicleStateChange: 'deleteScheduleVehicleStateChange',
    refreshVehicleTelematics: 'refreshVehicleTelematics',
    updateDamageReport: 'updateDamageReport',
    updateVehicle: 'updateVehicle',
  },
  Subscription: {
    fccMostCriticalVehicle: 'fccMostCriticalVehicle',
    continueTripResolution: 'continueTripResolution',
    serviceCancellationState: 'serviceCancellationState',
    fccDoorActuationStatus: 'fccDoorActuationStatus',
    passengerAuthenticationCardStateSubscription:
      'passengerAuthenticationCardStateSubscription',
    serviceCancellationActionCardState: 'serviceCancellationActionCardState',
    AdSeatStatus: 'AdSeatStatus',
    fccAdTelematicsCardTelemetry: 'fccAdTelematicsCardTelemetry',
    fccSelfDrivingSystemCardTelemetry: 'fccSelfDrivingSystemCardTelemetry',
    fccSelfDrivingSystemCardLocation: 'fccSelfDrivingSystemCardLocation',
    accountViewCanaryContext: 'accountViewCanaryContext',
    accountViewCanaryTimestamp: 'accountViewCanaryTimestamp',
    currentVehicleRoute: 'currentVehicleRoute',
    vehicleHubInformation: 'vehicleHubInformation',
    vehicleInteraction: 'vehicleInteraction',
    vehicleTelematics: 'vehicleTelematics',
  },
  Fragment: {
    useServiceCancellationAlert: 'useServiceCancellationAlert',
    serviceCancellationAlert: 'serviceCancellationAlert',
    CallVehicleButtonVehicle: 'CallVehicleButtonVehicle',
    AdFleetMapViewVehicle: 'AdFleetMapViewVehicle',
    AlertVehicleMapVehicle: 'AlertVehicleMapVehicle',
    AlertVehicleMapWaypoint: 'AlertVehicleMapWaypoint',
    AlertVehicleMapLocation: 'AlertVehicleMapLocation',
    EmergencyStopResolutionVehicle: 'EmergencyStopResolutionVehicle',
    PassengerUnbuckledActionCardVehicle: 'PassengerUnbuckledActionCardVehicle',
    serviceCancellationModalVehicle: 'serviceCancellationModalVehicle',
    WaypointAttendanceModalWaypoint: 'WaypointAttendanceModalWaypoint',
    AdVehicleDoorStatus: 'AdVehicleDoorStatus',
    AlertsModalSkeletonVehicle: 'AlertsModalSkeletonVehicle',
    AlertsModalSkeletonHeaderVehicle: 'AlertsModalSkeletonHeaderVehicle',
    AlertsModalSkeletonIncidentsListVehicle:
      'AlertsModalSkeletonIncidentsListVehicle',
    AlertsModalSkeletonIncidentsListAlert:
      'AlertsModalSkeletonIncidentsListAlert',
    AlertsModalSkeletonIncidentsListAdAlert:
      'AlertsModalSkeletonIncidentsListAdAlert',
    BoardingPassengersWaypoint: 'BoardingPassengersWaypoint',
    PassengerCardCustomer: 'PassengerCardCustomer',
    PassengerCardSeatInfo: 'PassengerCardSeatInfo',
    CabinSafetyCheckVehicle: 'CabinSafetyCheckVehicle',
    DepartureVehicle: 'DepartureVehicle',
    AlertModalTask: 'AlertModalTask',
    AlertModalAdAlert: 'AlertModalAdAlert',
    AdVehicleSeatingVehicle: 'AdVehicleSeatingVehicle',
    OperatorPromptVehicle: 'OperatorPromptVehicle',
    OperatorPromptAdAlert: 'OperatorPromptAdAlert',
    OperatorPromptAlert: 'OperatorPromptAlert',
    OperatorPromptByIdAdAlert: 'OperatorPromptByIdAdAlert',
    AdOnBoardPassengersVehicle: 'AdOnBoardPassengersVehicle',
    AdWaypointPassengersVehicle: 'AdWaypointPassengersVehicle',
    AdWaypointPassengersWaypoint: 'AdWaypointPassengersWaypoint',
    AdWaypointPassengersAuthenticationAlert:
      'AdWaypointPassengersAuthenticationAlert',
    AdPassengerRowCustomer: 'AdPassengerRowCustomer',
    AdPassengerRowSeatInfo: 'AdPassengerRowSeatInfo',
    GetPassengersWaypoint: 'GetPassengersWaypoint',
    GetPassengersPickupAction: 'GetPassengersPickupAction',
    GetPassengersDeliveryAction: 'GetPassengersDeliveryAction',
    AdPoolingToggle: 'AdPoolingToggle',
    AdAlertCardAlert: 'AdAlertCardAlert',
    CabinSafetyCheckAdAlertCardAlert: 'CabinSafetyCheckAdAlertCardAlert',
    EmergencyStopAdAlertCardAlert: 'EmergencyStopAdAlertCardAlert',
    OperatorCallAdAlertCardAlert: 'OperatorCallAdAlertCardAlert',
    PassengerCallAdAlertCardAlert: 'PassengerCallAdAlertCardAlert',
    PassengerUnbuckledAdAlertCardAlert: 'PassengerUnbuckledAdAlertCardAlert',
    ServiceCancellationAlertCardAlert: 'ServiceCancellationAlertCardAlert',
    WaypointAttendanceAdAlertCard: 'WaypointAttendanceAdAlertCard',
    AlertHistoryTaskStateChange: 'AlertHistoryTaskStateChange',
    AlertHistoryAdAlertStatusChange: 'AlertHistoryAdAlertStatusChange',
    AlertHistoryTask: 'AlertHistoryTask',
    AlertHistoryAdAlert: 'AlertHistoryAdAlert',
    BaseAlertCardTask: 'BaseAlertCardTask',
    BaseAlertCardAdAlert: 'BaseAlertCardAdAlert',
    BaseAlertCardVehicle: 'BaseAlertCardVehicle',
    ShowVehicleOnMapButtonVehicle: 'ShowVehicleOnMapButtonVehicle',
    VehicleIconLinkVehicle: 'VehicleIconLinkVehicle',
    AlertCardAlert: 'AlertCardAlert',
    DriverHasNotShownUpYetAlertCardAlert:
      'DriverHasNotShownUpYetAlertCardAlert',
    DrivingWithoutBreakAlertCardAlert: 'DrivingWithoutBreakAlertCardAlert',
    DrivingWithoutBreakTimeUntilBreach: 'DrivingWithoutBreakTimeUntilBreach',
    NoBookingAlertCardAlert: 'NoBookingAlertCardAlert',
    NoDriverAlertCardAlert: 'NoDriverAlertCardAlert',
    OutOfServiceAreaAlertCardAlert: 'OutOfServiceAreaAlertCardAlert',
    PassengerAuthenticationFallbackAlertCardAlert:
      'PassengerAuthenticationFallbackAlertCardAlert',
    PrebookingAlertCardAlert: 'PrebookingAlertCardAlert',
    VehicleHasLowSocAlertCardAlert: 'VehicleHasLowSocAlertCardAlert',
    VehicleHasNoHubReturnAlertCardAlert: 'VehicleHasNoHubReturnAlertCardAlert',
    VehicleIsStandingAlertCardAlert: 'VehicleIsStandingAlertCardAlert',
    VehicleNotBookableAlertCardAlert: 'VehicleNotBookableAlertCardAlert',
    DefaultActionsInProgressAlert: 'DefaultActionsInProgressAlert',
    DefaultActionsOpenAlert: 'DefaultActionsOpenAlert',
    DefaultMetaInProgressAlertVehicle: 'DefaultMetaInProgressAlertVehicle',
    DefaultMetaInProgressAlertEmployee: 'DefaultMetaInProgressAlertEmployee',
    DefaultMetaNewAlertEmployee: 'DefaultMetaNewAlertEmployee',
    DefaultMetaNewAlertVehicle: 'DefaultMetaNewAlertVehicle',
    DriverIconLinkEmployee: 'DriverIconLinkEmployee',
    MarkAsResolvedButtonAlert: 'MarkAsResolvedButtonAlert',
    MetaPhoneDriverVehicle: 'MetaPhoneDriverVehicle',
    MetaPhoneDriverEmployee: 'MetaPhoneDriverEmployee',
    TripInfoAlertCardStop: 'TripInfoAlertCardStop',
    DefaultAlertCardDataAlert: 'DefaultAlertCardDataAlert',
    MapAlertDrawerTask: 'MapAlertDrawerTask',
    MapAlertDrawerAdAlert: 'MapAlertDrawerAdAlert',
    VehicleDrawerAlert: 'VehicleDrawerAlert',
    VehicleDrawerAdAlert: 'VehicleDrawerAdAlert',
    UseDisplayedAlertsTask: 'UseDisplayedAlertsTask',
    UseDisplayedAlertsAdAlert: 'UseDisplayedAlertsAdAlert',
    AlertActionsAlert: 'AlertActionsAlert',
    AlertNote: 'AlertNote',
    AlertStatus: 'AlertStatus',
    PrebookingAlertResolveDialogAlert: 'PrebookingAlertResolveDialogAlert',
    DriverAlertHubServiceNotification: 'DriverAlertHubServiceNotification',
    DriverAlertTimeManagementNotification:
      'DriverAlertTimeManagementNotification',
    UseAlertTypeTranslationTask: 'UseAlertTypeTranslationTask',
    UseAlertTypeTranslationAdAlert: 'UseAlertTypeTranslationAdAlert',
    UseCurrentSortedAlertsTask: 'UseCurrentSortedAlertsTask',
    UseCurrentSortedAlertsAdAlert: 'UseCurrentSortedAlertsAdAlert',
    UseAlertCountAdAlert: 'UseAlertCountAdAlert',
    UseAlertCountAlert: 'UseAlertCountAlert',
    IsAssignedTask: 'IsAssignedTask',
    IsAssignedAdAlert: 'IsAssignedAdAlert',
    IsOpenTask: 'IsOpenTask',
    IsOpenAdAlert: 'IsOpenAdAlert',
    GetAlertSeverityTaskNotification: 'GetAlertSeverityTaskNotification',
    GetAlertSeverityAdAlert: 'GetAlertSeverityAdAlert',
    HscAlertsTableAlert: 'HscAlertsTableAlert',
    PrebookingAlertsTableAlert: 'PrebookingAlertsTableAlert',
    capacityPlanning: 'capacityPlanning',
    EmployeeIdentityEmployee: 'EmployeeIdentityEmployee',
    EmployeeFormFullProfile: 'EmployeeFormFullProfile',
    employeeNote: 'employeeNote',
    employeeNoteFile: 'employeeNoteFile',
    EmployeeProfilePictureFragment: 'EmployeeProfilePictureFragment',
    InactiveVehicle: 'InactiveVehicle',
    FleetMaintenanceCardVehicle: 'FleetMaintenanceCardVehicle',
    MainInspection: 'MainInspection',
    MileageBasedInpsection: 'MileageBasedInpsection',
    VICProcess: 'VICProcess',
    ADSeat: 'ADSeat',
    ReportDisruptionMenuItemVehicle: 'ReportDisruptionMenuItemVehicle',
    ReportDisruptionMenuItemFccWaypointAction:
      'ReportDisruptionMenuItemFccWaypointAction',
    ReportDisruptionMenuItemFccWaypointDeliveryAction:
      'ReportDisruptionMenuItemFccWaypointDeliveryAction',
    ReportDisruptionMenuItemTrip: 'ReportDisruptionMenuItemTrip',
    UnreachableStopVehicle: 'UnreachableStopVehicle',
    UnreachableStopPickupAction: 'UnreachableStopPickupAction',
    UnreachableStopCustomer: 'UnreachableStopCustomer',
    UnreachableStopStop: 'UnreachableStopStop',
    FleetMapViewVehicle: 'FleetMapViewVehicle',
    GetVehicleCounts: 'GetVehicleCounts',
    DurationBarVehicle: 'DurationBarVehicle',
    VehicleDrawerAcButton: 'VehicleDrawerAcButton',
    VehicleDrawerShiftCardVehicle: 'VehicleDrawerShiftCardVehicle',
    AlertAssignmentIconTask: 'AlertAssignmentIconTask',
    AlertAssignmentIconAdAlert: 'AlertAssignmentIconAdAlert',
    VehicleDrawerInfoCardVehicle: 'VehicleDrawerInfoCardVehicle',
    VehicleDrawerStatusBarVehicle: 'VehicleDrawerStatusBarVehicle',
    KeepOutZonePolygonArea: 'KeepOutZonePolygonArea',
    KeepOutZoneInfoCardArea: 'KeepOutZoneInfoCardArea',
    HereMapViewVehicle: 'HereMapViewVehicle',
    UseFollowVehicle: 'UseFollowVehicle',
    UseLocationSelectionVehicle: 'UseLocationSelectionVehicle',
    UseRebalancingCircleVehicle: 'UseRebalancingCircleVehicle',
    FilterByBookableOptionVehicle: 'FilterByBookableOptionVehicle',
    FilterByNotBookableOptionVehicle: 'FilterByNotBookableOptionVehicle',
    isInsideRebalancingZone: 'isInsideRebalancingZone',
    AdVehicleMarkerVehicle: 'AdVehicleMarkerVehicle',
    AdVehicleMarkerPickupAction: 'AdVehicleMarkerPickupAction',
    AdVehicleMarkerDeliveryAction: 'AdVehicleMarkerDeliveryAction',
    AdVehicleMarkerHubReturnAction: 'AdVehicleMarkerHubReturnAction',
    AdVehicleMarkerReallocationAction: 'AdVehicleMarkerReallocationAction',
    AlertLabelTask: 'AlertLabelTask',
    AlertLabelAdAlert: 'AlertLabelAdAlert',
    VehicleDestinationMarkerWaypointAction:
      'VehicleDestinationMarkerWaypointAction',
    VehicleDestinationMarkerPickupAction:
      'VehicleDestinationMarkerPickupAction',
    VehicleDestinationMarkerDeliveryAction:
      'VehicleDestinationMarkerDeliveryAction',
    VehicleDestinationMarkerHubReturnAction:
      'VehicleDestinationMarkerHubReturnAction',
    VehicleDestinationMarkerReallocationAction:
      'VehicleDestinationMarkerReallocationAction',
    VehicleMarkerVehicle: 'VehicleMarkerVehicle',
    VehicleMarkerPickupAction: 'VehicleMarkerPickupAction',
    VehicleMarkerDeliveryAction: 'VehicleMarkerDeliveryAction',
    VehicleMarkerHubReturnAction: 'VehicleMarkerHubReturnAction',
    VehicleMarkerReallocationAction: 'VehicleMarkerReallocationAction',
    VehicleListOverlayVehicle: 'VehicleListOverlayVehicle',
    VehicleListOverlayRowVehicle: 'VehicleListOverlayRowVehicle',
    NavigationStop: 'NavigationStop',
    areaCapacity: 'areaCapacity',
    historyChangeItem: 'historyChangeItem',
    CallControlsPreviewContainer: 'CallControlsPreviewContainer',
    rioTemplate: 'rioTemplate',
    rioComponentTextArea: 'rioComponentTextArea',
    rioComponentStatus: 'rioComponentStatus',
    rioComponentSeverity: 'rioComponentSeverity',
    rioComponentDisruptionType: 'rioComponentDisruptionType',
    rioComponentDate: 'rioComponentDate',
    rioComponentPassFail: 'rioComponentPassFail',
    rioComponentCheckbox: 'rioComponentCheckbox',
    rioComponentStaticValue: 'rioComponentStaticValue',
    rioRule: 'rioRule',
    rioReport: 'rioReport',
    rioAttachment: 'rioAttachment',
    rioUser: 'rioUser',
    rioBackofficeUser: 'rioBackofficeUser',
    rioEmployee: 'rioEmployee',
    SafetyDriverVehicle: 'SafetyDriverVehicle',
    ServiceCancellationDialogHeaderVehicle:
      'ServiceCancellationDialogHeaderVehicle',
    AffectedCustomersWaypoints: 'AffectedCustomersWaypoints',
    ServiceCancellationFormVehicle: 'ServiceCancellationFormVehicle',
    GetTripCounts: 'GetTripCounts',
    ServiceMetrics: 'ServiceMetrics',
    backofficeUser: 'backofficeUser',
    shiftPlannedHubReturnSectionOption: 'shiftPlannedHubReturnSectionOption',
    plannedHubReturnShiftSectionState: 'plannedHubReturnShiftSectionState',
    shiftDashboardEmployeeFragment: 'shiftDashboardEmployeeFragment',
    shiftStateFragment: 'shiftStateFragment',
    areaFragment: 'areaFragment',
    updateShiftEmployeeStatus: 'updateShiftEmployeeStatus',
    employeeLogPoolingStateChangedLogRecord:
      'employeeLogPoolingStateChangedLogRecord',
    employeeLogSendVehicleToHubLogRecord:
      'employeeLogSendVehicleToHubLogRecord',
    employeeLogShiftStateBreakLogRecord: 'employeeLogShiftStateBreakLogRecord',
    employeeLogShiftStateHubLogRecord: 'employeeLogShiftStateHubLogRecord',
    employeeLogShiftStateVehicleLogRecord:
      'employeeLogShiftStateVehicleLogRecord',
    employeeLogAreaLogRecord: 'employeeLogAreaLogRecord',
    employeeShiftChangedLogRecord: 'employeeShiftChangedLogRecord',
    updateShiftBreakFragment: 'updateShiftBreakFragment',
    updateComment: 'updateComment',
    ActionFields: 'ActionFields',
    ReducedAreaWithActions: 'ReducedAreaWithActions',
    RoadSegmentSuggestionFields: 'RoadSegmentSuggestionFields',
    SearchResultAreaItemV3: 'SearchResultAreaItemV3',
    AreaFields: 'AreaFields',
    historyItem: 'historyItem',
    SearchResultRoadBlockageItemV3: 'SearchResultRoadBlockageItemV3',
    RoadBlockageActivationFields: 'RoadBlockageActivationFields',
    RoadBlockageFields: 'RoadBlockageFields',
    RoadSegmentFields: 'RoadSegmentFields',
    SearchResultStopItemV3: 'SearchResultStopItemV3',
    StopFields: 'StopFields',
    StopSuggestionFields: 'StopSuggestionFields',
    SearchResultTurnRestrictionItemV3: 'SearchResultTurnRestrictionItemV3',
    TurnRestrictionActivationFields: 'TurnRestrictionActivationFields',
    TurnRestrictionFields: 'TurnRestrictionFields',
    ApproverUserFragment: 'ApproverUserFragment',
    GroupUserFragment: 'GroupUserFragment',
    BackofficeGroupHistory: 'BackofficeGroupHistory',
    BackofficeGroupDialogGroup: 'BackofficeGroupDialogGroup',
    UserTenant: 'UserTenant',
    AboutUserFormCardBackofficeUser: 'AboutUserFormCardBackofficeUser',
    BackofficerUserDetailViewBackofficeUser:
      'BackofficerUserDetailViewBackofficeUser',
    BackofficeUserFormData: 'BackofficeUserFormData',
    BackofficeUserHistory: 'BackofficeUserHistory',
    UserGroups: 'UserGroups',
    AdVehicleParkingStatus: 'AdVehicleParkingStatus',
    AdVehicleSpeedStatus: 'AdVehicleSpeedStatus',
    FlashVehicleMenuItemVehicle: 'FlashVehicleMenuItemVehicle',
    GoogleMapsMenuItemVehicle: 'GoogleMapsMenuItemVehicle',
    MatchDriverMenuItemVehicle: 'MatchDriverMenuItemVehicle',
    MessageVehicleMenuItemVehicle: 'MessageVehicleMenuItemVehicle',
    VehicleInteractionButton: 'VehicleInteractionButton',
    LastBoxState: 'LastBoxState',
    VehicleGroup: 'VehicleGroup',
    IsBookable: 'IsBookable',
    IsParkingSafely: 'IsParkingSafely',
    HasNoActiveMission: 'HasNoActiveMission',
    IsRebalancing: 'IsRebalancing',
    HasHubReturnError: 'HasHubReturnError',
    HasHubReturnNext: 'HasHubReturnNext',
    IsLowOnEnergy: 'IsLowOnEnergy',
    HasIgnitionSinceThreshold: 'HasIgnitionSinceThreshold',
    IsOverheating: 'IsOverheating',
    IsSubCooled: 'IsSubCooled',
    IsOpenedByTesterOrDriver: 'IsOpenedByTesterOrDriver',
    IsNotBookable: 'IsNotBookable',
    IsOpen: 'IsOpen',
    InLocation: 'InLocation',
    HasSafetyCheck: 'HasSafetyCheck',
    IsWav: 'IsWav',
    VehicleMissionCardHeader: 'VehicleMissionCardHeader',
    VehicleMissionCardVehicle: 'VehicleMissionCardVehicle',
    vehicleMissionPickupAction: 'vehicleMissionPickupAction',
    vehicleMissionDeliveryAction: 'vehicleMissionDeliveryAction',
    vehicleMissionHubReturnAction: 'vehicleMissionHubReturnAction',
    vehicleMissionReallocationAction: 'vehicleMissionReallocationAction',
    VehicleOpenMenuItemVehicle: 'VehicleOpenMenuItemVehicle',
    VehiclePrioritization: 'VehiclePrioritization',
    DamageReport: 'DamageReport',
    HubViewVehicleFragment: 'HubViewVehicleFragment',
    vehicleChangesBoxPcReplacedChange: 'vehicleChangesBoxPcReplacedChange',
    vehicleChangesBoxPcWipedChange: 'vehicleChangesBoxPcWipedChange',
    vehicleChangesCameraReplacedChange: 'vehicleChangesCameraReplacedChange',
    vehicleChangesCdisBoxReplacedChange: 'vehicleChangesCdisBoxReplacedChange',
    vehicleChangesKeyRfidReplacedChange: 'vehicleChangesKeyRfidReplacedChange',
    vehicleChangesRouterReplacedChange: 'vehicleChangesRouterReplacedChange',
    vehicleChangesTabletPhoneNumberReplacedChange:
      'vehicleChangesTabletPhoneNumberReplacedChange',
    vehicleChangesTabletReplacedChange: 'vehicleChangesTabletReplacedChange',
    vehicleChangesVehicleCreatedChange: 'vehicleChangesVehicleCreatedChange',
    vehicleChangesVehicleServiceAbortedChange:
      'vehicleChangesVehicleServiceAbortedChange',
    vehicleChangesVehicleStateUpdated: 'vehicleChangesVehicleStateUpdated',
    vehicleChangesVehicleStateUpdatedChange:
      'vehicleChangesVehicleStateUpdatedChange',
    vehicleChangesVehicleUpdatedChange: 'vehicleChangesVehicleUpdatedChange',
    VehicleCheckFragment: 'VehicleCheckFragment',
  },
}
