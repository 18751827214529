import type { TFunction } from 'i18next'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoumRight as Rights } from '@moia-dev/moia-token-claims'
import { fetchJson } from '@backoffice-frontend/common'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import {
  BooleanIcon,
  MoiaCard as Card,
  MoiaCardContent as CardContent,
  MoiaCardHeader as CardHeader,
  FormControlLabel,
  MoiaButton,
  MoiaGrid,
  MoiaIconLabel,
  MoiaSwitch,
  SwitchEnvironmentDropdown,
} from '@backoffice-frontend/patterns'
import { Restricted } from '@backoffice-frontend/restricted'
import { FeatureToggleAreaId } from './FeatureToggleAreaId'
import { Features } from './Features'
import { useFeatureToggles } from './useFeatureToggles'
import { useIsFeatureToggleEnabled } from './useIsFeatureToggleEnabled'

const getFeatureTranslation = (feature: Features, t: TFunction) => {
  switch (feature) {
    case Features.FEATURE_TOGGLE_TEST:
      return t('Feature to test Feature Toggles')
    case Features.SUBSCRIPTION_SUPPORT:
      return t('Subscription Support')
    case Features.VEHICLE_STANDING_ALERT:
      return t('Show "Vehicle standing" alert')
    case Features.TURN_RESTRICTIONS:
      return t('Turn restrictions')
    case Features.NEXT_GEN_SAM_TABLES:
      return t('Use the new SAM tables')
    case Features.VEHICLE_CHECKS_REPORTS:
      return t('Vehicle checks report')
    case Features.MUNICH_DEMO:
      return t('All features developed for the Munich demo')
    case Features.HAMBURG_KPI_DASHBOARD:
      return t('Hamburg toggle for the KPI Reporting Dashboard')
    case Features.DSBOSS_SCENARIOS_TABLE_PACE:
      return t('DSBOSS scenarios table pace')
    case Features.EMERGENCY_DISABLE_SERVICE:
      return t('Emergency disable service')
    case Features.FLEETS_AREA:
      return t('Fleets area')
    case Features.OPERATOR_ORCHESTRATOR:
      return t('Operator orchestrator')
    default:
      return feature
  }
}

export const FeatureToggle = ({
  children,
  feature,
  replacement,
}: {
  children: ReactNode
  feature: Features
  replacement?: ReactNode
}) => {
  const isEnabled = useIsFeatureToggleEnabled(feature)
  return <>{isEnabled ? children : (replacement ?? null)}</>
}

export const FeatureTogglesView = () => {
  const { t } = useTranslation(FeatureToggleAreaId)
  const { features: enabledFeatures, setFeatures } = useFeatureToggles()
  const [throwError, setThrowError] = useState(false)

  const handleCreateError = () => {
    setThrowError(true)
  }

  const handleToggleFeature = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error typecast is save as only features can be selected
    const nextFeature: Features = event.target.value
    const nextFeatures = enabledFeatures.includes(nextFeature)
      ? enabledFeatures.filter(feature => feature !== nextFeature)
      : [...enabledFeatures, nextFeature]

    setFeatures(nextFeatures)
  }

  if (throwError) {
    throw new Error(
      'Account area error, intentionally thrown for testing purposes.',
    )
  }

  return (
    <Restricted requiredRights={[Rights.DEV_USE_FEATURE_TOGGLES]}>
      <Card>
        <CardHeader title={t('Environment')} />
        <CardContent>
          <SwitchEnvironmentDropdown />
        </CardContent>

        <CardHeader title={t('Enable feature')} />
        <CardContent>
          <FeatureToggle feature={Features.FEATURE_TOGGLE_TEST}>
            <MoiaIconLabel>
              <BooleanIcon value />
              Test feature toggle works
            </MoiaIconLabel>
          </FeatureToggle>

          {Object.values(Features).map(feature => (
            <FormControlLabel
              key={feature}
              css={{ width: '100%' }}
              control={
                <MoiaSwitch
                  value={feature}
                  onChange={handleToggleFeature}
                  checked={enabledFeatures.includes(feature)}
                />
              }
              label={getFeatureTranslation(feature, t)}
            />
          ))}
          <MoiaGrid column>
            <MoiaButton onClick={handleCreateError}>
              {t('Throw an Error!')}
            </MoiaButton>
            {getEnvironment() === Environment.local && (
              <MoiaButton
                onClick={() => {
                  void fetchJson('http://localhost:8081/api/reset', {
                    method: 'PATCH',
                  })
                }}
              >
                Reset Mock Server
              </MoiaButton>
            )}
          </MoiaGrid>
        </CardContent>
      </Card>
    </Restricted>
  )
}
